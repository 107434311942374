import React from "react"
import "./style.scss"

import SliderPanel from "../SliderPanel"

import { Navigation, Autoplay, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/bundle"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDownLong } from "@fortawesome/free-solid-svg-icons"

const ComponentSectionsMainHeroSlider = (props) => {
    let { Slider_Panels } = props.contents

    return (
        <div className="page-section full-width component-main-hero-slider">
            <Swiper
                slidesPerView={1}
                loop={true}
                modules={[Navigation, Autoplay, Keyboard]}
                navigation={true}
                autoplay={{ delay: 5000 }}
                keyboard={true}
            >
                {Slider_Panels.map((panel_data, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <SliderPanel contents={panel_data} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            {/* <div className="scroll-down flex-cc">
                <span>scroll</span>
                <FontAwesomeIcon icon={faArrowDownLong} size="2x" style={{ color: "white" }} />
            </div> */}
        </div>
    )
}

export default ComponentSectionsMainHeroSlider
