import React from "react"
import "./style.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons"

const getSocialIcon = (i) => {
    const mappings = {
        facebook: faFacebookSquare,
    }
    return mappings[i]
}

const Footer = (props) => {
    const { Company_Email, Company_Phone, footer } = props.contents
    const { column_info, column_locations, column_socials } = footer

    return (
        <footer>
            <div className="grid grid-4 content-wrap">
                {/* <!-- Column Info START --> */}
                <div className="grid-child">
                    <h3>{column_info.Title}</h3>
                    {/* {column_info.Logo && <img src={column_info.Logo.url} alt={column_info.Logo.alternativeText} />} */}
                    <p>{column_info.Body}</p>
                </div>
                {/* <!-- Column Info END --> */}

                {/* <!-- Column Locations START --> */}
                <div className="grid-child">
                    <h3>{column_locations.Title}</h3>

                    <ul>
                        {column_locations.locations.map((location, i) => (
                            <li key={i}>{location.Address}</li>
                        ))}
                    </ul>
                </div>
                {/* <!-- Column Locations END --> */}

                {/* <!-- Column Contact START --> */}
                <div className="grid-child">
                    <h3>Contact</h3>

                    <p>
                        <a href={`mailto:${Company_Email}`}>{Company_Email}</a>
                        <br />
                        <a href={`tel:${Company_Phone}`}>{Company_Phone}</a>
                    </p>
                </div>
                {/* <!-- Column Contact END --> */}

                {/* <!-- Column Socials START --> */}
                <div className="grid-child">
                    <h3>Socials</h3>
                    <p>
                        {column_socials.map((social) => {
                            const { Social_Url, Social_Icon } = social
                            return (
                                <a
                                    target="_blank"
                                    key={Social_Icon}
                                    rel="noreferrer"
                                    className="social-icon"
                                    href={Social_Url}
                                >
                                    <FontAwesomeIcon icon={getSocialIcon(Social_Icon)} size="2x"></FontAwesomeIcon>
                                </a>
                            )
                        })}
                    </p>
                </div>
                {/* <!-- Column Socials END --> */}
            </div>

            <div className="copyright full-width flex-cc">
                <p>Liability limited by a scheme approved under Professional Standards Legislation</p>
                <p>
                    <span>{new Date().getFullYear()} VM Family Lawyers, LTD. all rights reserved.</span>
                    <span className="built-by">
                        &nbsp;Built by
                        <a target="_blank" rel="noreferrer" href="mailto:info@spinitup.com.au">
                            &nbsp;SIUS
                        </a>
                    </span>
                </p>
            </div>
        </footer>
    )
}

export default Footer
