import React from "react"
import "../../styles/elements.scss"

const Button = (props) => {
    const { border, onclick, newTab, href, children } = props

    let buttonClass = `button ${border ? "bordered" : ""}`
    let buttonTarget = newTab ? "_blank" : ""
    if (onclick) {
        return (
            <a className={buttonClass} href={href} onClick={onclick} target={buttonTarget}>
                {children}
            </a>
        )
    }

    return (
        <a className={buttonClass} href={href} target={buttonTarget}>
            {children}
        </a>
    )
}

export default Button
