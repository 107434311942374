import React from "react"
import Button from "../Button"
import "./style.scss"

const ComponentSectionsSimpleCta = (props) => {
    const { Heading, page, Button_Text } = props.contents

    return (
        <div className="page-section full-width component-cimple-cta">
            <div className="content-wrap flex-cc">
                <h3 className="text-center">{Heading}</h3>
                <Button href={page.slug}>{Button_Text}</Button>
            </div>
        </div>
    )
}

export default ComponentSectionsSimpleCta
