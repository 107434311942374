import React from "react"
import Button from "../Button"
import "./style.scss"

const ComponentSectionsCtaBanner = (props) => {
    const { Body_Text, Banner_Image, page, Button_Text } = props.contents

    const banner_style = Banner_Image ? { backgroundImage: `url("${Banner_Image.url}")` } : {}

    return (
        <div className="page-section dark-underlay full-width component-cta-banner" style={banner_style}>
            <div className="content-wrap flex-cc">
                <p className="section-copy richtext_paragraph">{Body_Text}</p>
                {Button_Text && <Button href={page.slug}>{Button_Text}</Button>}
            </div>
        </div>
    )
}

export default ComponentSectionsCtaBanner
