import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import "../styles/global.scss"

import ContentSections from "../components/ContentSections"
import Nav from "../components/Nav"

export default function Page(props) {
    const { pageContext } = props
    const { global } = pageContext

    return (
        <>
            <Helmet title={`${pageContext.page.shortName} | ${pageContext.global.metaTitleSuffix}`} defer={false}>
                <script defer data-domain="vmfamilylawyers.com.au" src="https://plausible.hotpheex.wtf/js/script.js"></script>
            </Helmet>
            <Nav contents={global} />
            <ContentSections sections={pageContext.page.contentSections} />
            <Footer contents={global} />
        </>
    )
}
