import React from "react"
import Button from "../Button"
import "./style.scss"

const ComponentLinksButtonLink = (props) => {
    const { url, text, newTab, type, page } = props.contents

    const border = type === "primary" ? false : true
    const final_url = page ? page.slug : url

    return (
        <div className="page-section content-wrap component-links-button-link">
            <Button href={final_url} {...border} {...newTab}>
                {text}
            </Button>
        </div>
    )
}

export default ComponentLinksButtonLink
