import React from "react"
import ComponentElementsBigTitle from "../components/ComponentElementsBigTitle"
import ComponentSectionsTextSection from "../components/ComponentSectionsTextSection"
import ComponentLinksButtonLink from "../components/ComponentLinksButtonLink"
import ComponentElementsTextBlock from "../components/ComponentElementsTextBlock"
import ComponentSectionsCtaBanner from "../components/ComponentSectionsCtaBanner"
import ComponentSectionsLocations from "../components/ComponentSectionsLocations"
import ComponentSectionsSimpleCta from "../components/ComponentSectionsSimpleCta"
import ComponentSectionsLeadForm from "../components/ComponentSectionsLeadForm"
import ComponentSectionsProfileSection from "../components/ComponentSectionsProfileSection"
import ComponentSectionsSmallBannerText from "../components/ComponentSectionsSmallBannerText"
import ComponentSectionsChecklistSection from "../components/ComponentSectionsChecklistSection"
import ComponentSectionsPaymentInfoSection from "../components/ComponentSectionsPaymentInfoSection"
import ComponentSectionsMainHeroSlider from "../components/ComponentSectionsMainHeroSlider"

const components = {
    STRAPI_ComponentElementsBigTitle: ComponentElementsBigTitle,
    STRAPI_ComponentSectionsTextSection: ComponentSectionsTextSection,
    STRAPI_ComponentLinksButtonLink: ComponentLinksButtonLink,
    STRAPI_ComponentElementsTextBlock: ComponentElementsTextBlock,
    STRAPI_ComponentSectionsCtaBanner: ComponentSectionsCtaBanner,
    STRAPI_ComponentSectionsLocations: ComponentSectionsLocations,
    STRAPI_ComponentSectionsSimpleCta: ComponentSectionsSimpleCta,
    STRAPI_ComponentSectionsLeadForm: ComponentSectionsLeadForm,
    STRAPI_ComponentSectionsProfileSection: ComponentSectionsProfileSection,
    STRAPI_ComponentSectionsSmallBannerText: ComponentSectionsSmallBannerText,
    STRAPI_ComponentSectionsChecklistSection: ComponentSectionsChecklistSection,
    STRAPI_ComponentSectionsPaymentInfoSection: ComponentSectionsPaymentInfoSection,
    STRAPI_ComponentSectionsMainHeroSlider: ComponentSectionsMainHeroSlider,
}

export default function ContentSections(props) {
    return (
        <>
            {props.sections.map((section) => {
                if (section.__typename in components) {
                    const Component = components[section.__typename]
                    return <Component key={section.id} contents={section} />
                } else {
                    return <div key={crypto.randomUUID()}>Missing Section component: {section.__typename}</div>
                }
            })}
        </>
    )
}
