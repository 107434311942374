import React from "react"
import "./style.scss"

import MediumTitle from "../MediumTitle"
import Button from "../Button"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faCalendarCheck, faCreditCard, faMedkit, faHome } from "@fortawesome/free-solid-svg-icons"

const getIcon = (i) => {
    const mappings = {
        calendar: faCalendar,
        calendar_check: faCalendarCheck,
        creditcard: faCreditCard,
        medkit: faMedkit,
        home: faHome,
    }
    return mappings[i]
}

const ComponentSectionsPaymentInfoSection = (props) => {
    let { Heading, Sub_Text, Icon_Info, Body_Text, Button_Text, page, Background_Image } = props.contents

    return (
        <div
            className="page-section dark-underlay full-width component-payment-info"
            style={{ backgroundImage: Background_Image ? `url("${Background_Image.url}")` : "none" }}
        >
            <div className="content-wrap flex-cc">
                <MediumTitle>{Heading}</MediumTitle>
                <p className="section-copy richtext_paragraph">{Sub_Text}</p>

                <div className="options-wrapper flex-cc">
                    {Icon_Info.map((info_option, i) => {
                        return (
                            <div className="option flex-cc" key={i}>
                                <FontAwesomeIcon icon={getIcon(info_option.Icon)} scale="2x" />
                                <span>{info_option.Text}</span>
                            </div>
                        )
                    })}
                </div>

                <p className="section-copy">{Body_Text}</p>
                <Button href={page.slug}>{Button_Text}</Button>
            </div>
        </div>
    )
}

export default ComponentSectionsPaymentInfoSection
