import React from "react"
import ComponentElementsBigTitle from "../ComponentElementsBigTitle"
import ComponentSectionsTextSection from "../ComponentSectionsTextSection"
import MediumTitle from "../MediumTitle"
import './style.scss'

const ComponentSectionsProfileSection = (props) => {
    const { profile, Profile_Pinned, Profile_Location } = props.contents
    const transformed_data = {
        Body_Copy: profile.About_Text,
        Section_Image: profile.Profile_Image,
        Image_Pinned: Profile_Pinned,
        Image_Location: Profile_Location,
    }
    const big_title_data = { Title_Text: profile.Name, blue_color: true }

    return (
        <div className="profile">
            <ComponentElementsBigTitle contents={big_title_data} />
            {profile.Role &&
                <MediumTitle>{profile.Role}</MediumTitle>
            }
            <ComponentSectionsTextSection contents={transformed_data} />
        </div>
    )
}

export default ComponentSectionsProfileSection
