import React from "react"
import "./style.scss"

import MediumTitle from "../MediumTitle"
import Button from "../Button"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const ComponentSectionsChecklistSection = (props) => {
    let { Heading, Check_Items, Body_Text, Button_Text, Background_Image, page } = props.contents

    return (
        <div
            className="page-section dark-underlay full-width component-checklist-section"
            style={{ backgroundImage: Background_Image ? `url("${Background_Image.url}")` : "none" }}
        >
            <div className="content-wrap flex-cc">
                <MediumTitle>{Heading}</MediumTitle>

                <div className="check-wrapper">
                    {Check_Items.map((check_item, i) => {
                        return (
                            <div className="check-item" key={i}>
                                <div className="check-icon">
                                    <FontAwesomeIcon icon={faCheck} size="2x" />
                                </div>

                                <p className="check-description richtext_paragraph">{check_item.Check_Description}</p>
                            </div>
                        )
                    })}
                </div>

                <p className="section-copy">{Body_Text}</p>
                <Button href={page.slug}>{Button_Text}</Button>
            </div>
        </div>
    )
}

export default ComponentSectionsChecklistSection
