import React from "react"
import BigTitle from "../BigTitle"
import Button from "../Button"
import "./style.scss"

const SliderPanel = (props) => {
    let { Title, Description, Hero_Image, Button_Text, Text_Position, page } = props.contents

    let grid_direction = Text_Position.toLowerCase() === "right" ? "reverse" : ""
    return (
        <div
            className="slider-panel flex-cc dark-underlay"
            style={{ backgroundImage: Hero_Image ? `url("${Hero_Image.url}")` : "none" }}
        >
            <div className={`simple-grid-2 content-wrap ${grid_direction}`}>
                <div className="grid__child grid__child-content flex-cc">
                    <BigTitle>{Title}</BigTitle>
                    <p className="richtext_paragraph">{Description}</p>

                    {page && <Button href={page.slug}>{Button_Text ? Button_Text : page.shortName}</Button>}
                </div>
            </div>
        </div>
    )
}

export default SliderPanel
