import React, { useEffect, useState } from "react"
import "./style.scss"
import Button from "../Button"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faPhone } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

// README https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#use-getprops-for-advanced-link-styling

library.add(faBars, faPhone)

const Nav = (props) => {
    const { navbar, metaTitleSuffix, Company_Phone } = props.contents

    const isActive = ({ isCurrent }) => (isCurrent ? { className: "active-page" } : null)

    // Toggle mobile menu
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    function toggleMobileMenu() {
        setMobileMenuOpen(!mobileMenuOpen)
    }

    return (
        <nav>
            <div className="nav-wrap content-wrap">
                <div className="menu-wrapper">
                    <div className="icon-wrapper" onClick={toggleMobileMenu}>
                        <FontAwesomeIcon className="mobile-menu-icon" icon="fa-solid fa-bars" size="2x" />
                    </div>

                    <ul className={mobileMenuOpen ? "mobile_menu_open" : ""}>
                        {navbar.menu.pages.map((page) => {
                            return (
                                <li key={page.shortName}>
                                    <Link to={page.slug} getProps={isActive} onClick={toggleMobileMenu}>
                                        {page.shortName}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className="logo-wrapper flex-cc">
                    <a href="/">{navbar.logo ? <img src={navbar.logo.url} alt="Main Logo" /> : metaTitleSuffix}</a>
                </div>

                <div className="phone-contact-wrapper">
                    <div className="phone-button">
                        <Button border={true} href={`tel:${Company_Phone}`}>
                            {Company_Phone}
                        </Button>
                    </div>
                    <div className="phone-icon">
                        <a href={`tel:${Company_Phone}`}>
                            <FontAwesomeIcon className="mobile-phone-icon" icon="fa-solid fa-phone" size="2x" />
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Nav
