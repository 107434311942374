import React from "react"
import blue_pin from "../../images/blue-pin.png"
import "./style.scss"

export const PinnedSectionImage = (props) => {
    const { Section_Image } = props

    const randomImageScale = 0.8 - Math.random() * 0.2
    const randomImageRotate = Math.random() * 12 - 6
    return (
        <div className="pinned_image" style={{ transform: `scale(${randomImageScale})` }}>
            <img className="pin-image" src={blue_pin} alt="Blue Pin" />
            <img
                className="content-image"
                style={{ transform: `rotate(${randomImageRotate}deg)` }}
                src={Section_Image.url}
                alt={Section_Image.alternativeText ? Section_Image.alternativeText : ""}
            />
        </div>
    )
}

export const NonPinnedSectionImage = (props) => {
    const { Section_Image } = props
    return <img src={Section_Image.url} alt={Section_Image.alternativeText ? Section_Image.alternativeText : ""} />
}

const ComponentSectionsTextSection = (props) => {
    const { Heading, Body_Copy, Section_Image, Image_Pinned, Image_Location } = props.contents

    let grid_direction = Image_Location.toLowerCase() === "right" ? " reverse" : ""
    let sectionImageComponent;

    // Select right component to use in image section
    if (Section_Image && Image_Pinned) {
        sectionImageComponent = <PinnedSectionImage Section_Image={Section_Image} />
    } else if (Section_Image) {
        sectionImageComponent = <NonPinnedSectionImage Section_Image={Section_Image} />
    } 

    return (
        <div className="page-section content-wrap component-text-section">
            <div className={sectionImageComponent ? `simple-grid-2${grid_direction}${Section_Image && !Image_Pinned ? " no-pin-image": ""}` : 'no-image'}>
                {sectionImageComponent && 
                    <div className="grid__child grid__child-image">{sectionImageComponent}</div>
                }

                <div className="grid__child grid__child-text">
                    {/* If headding render heading component */}
                    {Heading && <h3>{Heading}</h3>}

                    <p className="richtext_paragraph">{Body_Copy}</p>
                </div>
            </div>
        </div>
    )
}

export default ComponentSectionsTextSection
