import React from "react"
import "./style.scss"
import ReactMarkdown from "react-markdown"

const ComponentSectionsSmallBannerText = (props) => {
    const { Heading, Body_Text } = props.contents

    return (
        <div className="page-section component-small-banner-text">
            <div className="content-wrap flex-cc">
                <h3 className="text-center">{Heading}</h3>
                <div className="richtext_paragraph text-center">
                    <ReactMarkdown children={Body_Text} />
                </div>
            </div>
        </div>
    )
}

export default ComponentSectionsSmallBannerText
