import React from "react"
import "./style.scss"
import ReactMarkdown from "react-markdown"

const ComponentElementsTextBlock = (props) => {
    const { Block_Content } = props.contents

    return (
        <div className="page-section content-wrap component-text-block">
            <div className="richtext_paragraph">
                <ReactMarkdown children={Block_Content} />
            </div>
        </div>
    )
}

export default ComponentElementsTextBlock
