import React from "react"
import BigTitle from "../BigTitle"
import "./style.scss"

const ComponentElementsBigTitle = (props) => {
    const { Title_Text, blue_color, margin_bottom } = props.contents
    return (
        <div className={`page-section content-wrap component-big-title${blue_color ? ' blue-color':''}${margin_bottom ? '':' no-margin-bottom'}`}>
            <BigTitle>{Title_Text}</BigTitle>
        </div>
    )
}

export default ComponentElementsBigTitle
