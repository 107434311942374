import React, { useState, useRef } from "react"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import BigTitle from "../BigTitle"
import "./style.scss"

const ComponentSectionsLeadForm = (props) => {
    const { Title, Banner_Image, form } = props.contents
    const { Show_Email_Field, Show_Message_Field, Show_Name_Field, Show_Phone_Field } = form

    const [token, setToken] = useState(null)
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(null)
    const captcha = useRef()

    const backend_url = process.env.GATSBY_LAMBDA_FUNCTION_URL

    let banner_style = Banner_Image ? { backgroundImage: `url("${Banner_Image.url}")` } : {}

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!token) {
            setError("Please verify the Captcha")
            return false
        } else {
            setError(null)
            setSending("Sending Email...")

            const res = await fetch(backend_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: e.target.name.value,
                    email: e.target.email.value,
                    phone: e.target.phone.value,
                    message: e.target.message.value,
                    token,
                }),
            })
            const resJson = await res.json()
            if (!res.ok) {
                setError(resJson.error)
                console.log("ERROR", resJson.error)
            } else {
                console.log("SUCCESS", resJson.message)
                captcha.current.resetCaptcha()
                setToken(null)
                setSending("Thank you for your enquiry")
            }
        }
    }

    return (
        <div className="page-section full-width dark-underlay component-lead-form" style={banner_style}>
            <div className="content-wrap flex-cc">
                <BigTitle>{Title}</BigTitle>

                <div className="form-container">
                    <form id="contactForm" className="simple-grid-2" onSubmit={onSubmit}>
                        {Show_Name_Field && (
                            <div className="form-group">
                                <label>
                                    Your Name
                                    <input id="name" type="text" className="form-control" required />
                                </label>
                            </div>
                        )}
                        {Show_Email_Field && (
                            <div className="form-group">
                                <label>
                                    Your Email
                                    <input id="email" type="email" className="form-control" required />
                                </label>
                            </div>
                        )}

                        {Show_Phone_Field && (
                            <div className="form-group">
                                <label>
                                    Your Phone Number
                                    <input id="phone" type="text" className="form-control" required />
                                </label>
                            </div>
                        )}

                        {Show_Message_Field && (
                            <div className="form-group">
                                <label>
                                    Your Message
                                    <textarea id="message" type="text" className="form-control" required />
                                </label>
                            </div>
                        )}

                        <div className="form-group">
                            <HCaptcha
                                sitekey={process.env.GATSBY_HCAPTCHA_SITEKEY}
                                onVerify={(token) => setToken(token)}
                                ref={captcha}
                                onExpire={() => setToken(null)}
                            />
                        </div>

                        <div className="form-group">
                            {error && <p className="error-alert">{error}</p>}
                            {sending ? (
                                <p className="form-message">{sending}</p>
                            ) : (
                                <button className="submit-button">Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ComponentSectionsLeadForm
